<template>
  <v-dialog v-model="dialog" max-width="700px" persistent>
    <v-form ref="form" @submit.prevent="onSubmit">
      <v-card>
        <v-card-title>
          {{ category.value ? 'Edit' : 'Create' }} category

          <v-spacer />

          <v-btn text icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-text-field v-model="category.text" label="Title" data-testid="category-title" />

          <v-text-field
            v-model="category.max_amount"
            label="Max amount"
            type="number"
            data-testid="category-amount"
          />

          <v-subheader>Plus amounts in the current month</v-subheader>

          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">ID</th>
                  <th class="text-left">Description</th>
                  <th class="text-left">Amount</th>
                  <th class="text-left">Delete</th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="(item, index) in category.plus_amounts"
                  :key="index"
                >
                  <td>{{ item.id }}</td>

                  <td>
                    <v-text-field
                      v-model="item.description"
                      data-testid="category-plus-amount-description"
                    />
                  </td>

                  <td>
                    <v-text-field
                      v-model="item.amount"
                      type="number"
                      data-testid="category-plus-amount-amount"
                    >
                      <template v-slot:append>{{ currency }}</template>
                    </v-text-field>
                  </td>

                  <td>
                    <v-btn color="error" icon @click="category.plus_amounts.splice(index, 1)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>

        <v-card-actions>
          <v-btn text @click="addPlusAmount" data-testid="add-category-plus-amount">
            Create plus amount
          </v-btn>

          <v-btn text @click="getPreviousMonthSum">
            Read from previous month
          </v-btn>

          <v-spacer />

          <v-btn text @click="close">Cancel</v-btn>

          <v-btn color="primary" type="submit" data-testid="category-save">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { parse, subMonths, format } from 'date-fns';

export default {
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      category: {
        text: '',
        max_amount: 0,
        plus_amounts: [],
      },
    };
  },

  computed: {
    ...mapState('finance', ['currency', 'currentMonth']),
  },

  methods: {
    ...mapActions('finance', ['getSumOfMonth']),

    open(data) {
      this.category = data || {
        text: '',
        max_amount: 0,
        plus_amounts: [],
      };
      this.dialog = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    close() {
      this.reject();
      this.dialog = false;
    },

    onSubmit() {
      if (this.$refs.form.validate()) {
        this.resolve({
          text: this.category.text,
          maxAmount: this.category.max_amount,
          plusAmounts: this.category.plus_amounts,
        });
        this.dialog = false;
      }
    },

    addPlusAmount(amount, description) {
      this.category.plus_amounts.push({
        id: '#',
        description: description || '',
        amount: amount || '',
        month: this.currentMonth,
      });
    },

    async getPreviousMonthSum() {
      const sum = await this.getSumOfMonth({
        category: this.category,
        month: format(subMonths(parse(this.currentMonth, 'y-MM', new Date()), 1), 'y-MM'),
      });
      this.addPlusAmount(sum, 'Previous month');
    },
  },
};
</script>
