<template>
  <layout title="Finances">
    <template v-slot:menu>
      <v-spacer />
    </template>

    <template v-slot:vertical_more>
      <v-list-item @click="openImportServiceChooser">
        <v-icon class="mr-2">mdi-database-import</v-icon>
        Import
      </v-list-item>

      <v-divider />
    </template>

    <template v-slot:content>
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="8">
            <finances-data-table @edit="onEditFinanceClick" />
          </v-col>

          <v-col cols="12" sm="4">
            <category-table @edit="onEditCategoryClick" @delete="onDeleteCategory" />
          </v-col>
        </v-row>
      </v-container>

      <v-speed-dial
        v-model="fab"
        bottom
        right
        fixed
        direction="top"
        transition="slide-y-reverse-transition"
      >
        <template v-slot:activator>
          <v-btn
            v-model="fab"
            color="blue darken-2"
            dark
            fab
            data-testid="finance-dial"
          >
            <v-icon>
              {{ fab ? 'mdi-close' : 'mdi-plus' }}
            </v-icon>
          </v-btn>
        </template>

        <v-btn
          fab
          dark
          small
          color="error"
          @click="onCreateFinanceClick('expense')"
          data-testid="create-finance-expense"
        >
          <v-icon>mdi-cash-minus</v-icon>
        </v-btn>

        <v-btn
          fab
          dark
          small
          color="green"
          @click="onCreateFinanceClick('income')"
          data-testid="create-finance-income"
        >
          <v-icon>mdi-cash-plus</v-icon>
        </v-btn>

        <v-btn
          fab
          dark
          small
          color="primary"
          @click="onCreateCategoryClick"
          data-testid="create-finance-category"
        >
          <v-icon>mdi-wallet-plus-outline</v-icon>
        </v-btn>
      </v-speed-dial>

      <v-snackbar
        v-model="$store.state.notionImportFinished"
        absolute
        top
        right
        color="success"
      >
        Notion import finished. Finances reloaded...
      </v-snackbar>

      <edit-finance ref="editFinanceDialog" />
      <edit-category ref="editCategoryDialog" />
      <choose-import-service ref="chooseImportServiceDialog" />
    </template>
  </layout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { format, isValid, parse } from 'date-fns';
import Layout from '../layouts/Main.vue';
import EditFinance from '../components/finance/dialog/EditFinance.vue';
import FinancesDataTable from '../components/finance/FinancesDataTable.vue';
import CategoryTable from '../components/finance/CategoryTable.vue';
import EditCategory from '../components/finance/dialog/EditCategory.vue';
import ChooseImportService from '../components/finance/dialog/ChooseImportService.vue';

export default {
  components: {
    Layout,
    EditFinance,
    FinancesDataTable,
    CategoryTable,
    EditCategory,
    ChooseImportService,
  },

  data() {
    return {
      fab: false,
    };
  },

  computed: {
    ...mapState('finance', ['finances', 'categories']),
    ...mapState('workspace', ['currentWorkspace']),

    currentMonth: {
      get() {
        return this.$store.state.finance.currentMonth;
      },

      set(value) {
        this.$store.state.finance.currentMonth = value;
      },
    },
  },

  watch: {
    currentMonth() {
      this.listCategories();
      this.listFinances();

      this.$router.replace({ name: 'Finance', params: { month: this.currentMonth } });
    },

    currentWorkspace() {
      this.listCategories();
      this.listFinances();
    },

    // eslint-disable-next-line object-shorthand
    '$store.state.notionImportFinished'() {
      if (this.$store.state.notionImportFinished) {
        this.listCategories();
        this.listFinances();
      }
    },
  },

  methods: {
    ...mapActions('finance', ['listFinances', 'listCategories', 'saveCategory', 'deleteCategory', 'saveFinance']),

    async onCreateFinanceClick(type) {
      try {
        const data = await this.$refs.editFinanceDialog.open({ type });
        await this.saveFinance(data);
        await this.listFinances();
      } catch (e) {
        if (typeof e !== 'undefined') {
          console.error(e);
        }
      }
    },

    async onEditFinanceClick(finance) {
      try {
        const data = await this.$refs.editFinanceDialog.open(
          JSON.parse(JSON.stringify(finance)),
        );

        await this.saveFinance({ ...finance, ...data });
        await this.listFinances();
      } catch (e) {
        if (typeof e !== 'undefined') {
          console.error(e);
        }
      }
    },

    async onCreateCategoryClick() {
      try {
        const data = await this.$refs.editCategoryDialog.open();
        await this.saveCategory({ data });
        await this.listCategories();
      } catch (e) {
        if (typeof e !== 'undefined') {
          console.error(e);
        }
      }
    },

    async onEditCategoryClick(category) {
      try {
        const data = await this.$refs.editCategoryDialog.open(
          JSON.parse(JSON.stringify(category)),
        );

        await this.saveCategory({ categoryId: category.value, data });
        await this.listCategories();
      } catch (e) {
        if (typeof e !== 'undefined') {
          console.error(e);
        }
      }
    },

    async onDeleteCategory(category) {
      await this.deleteCategory({ categoryId: category.value });
      await this.listCategories();
    },

    openImportServiceChooser() {
      this.$refs.chooseImportServiceDialog.open();
    },
  },

  async mounted() {
    if (this.$route.params.month) {
      const parsed = parse(this.$route.params.month, 'yyyy-MM', new Date());

      if (isValid(parsed)) {
        this.currentMonth = format(parsed, 'yyyy-MM');
      } else {
        this.$router.replace({ name: 'Finance', params: { month: this.currentMonth } });
      }
    } else {
      this.$router.replace({ name: 'Finance', params: { month: this.currentMonth } });
    }

    await this.listCategories();
    await this.listFinances();
  },
};
</script>
