<template>
  <v-dialog v-model="dialog" max-width="450px" persistent>
    <v-card>
      <v-card-title>Choose service to import from:</v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="4" class="d-flex justify-center">
            <router-link
              v-if="$store.state.user.hasNotionToken"
              :to="{ name: 'Finance-Notion-Import' }"
            >
              <v-img width="40" src="/img/icons/notion.svg" />
              Notion
            </router-link>

            <a v-else :href="notionRedirectUrl">
              <v-img width="40" src="/img/icons/notion.svg" />
              Notion
            </a>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn text @click="close">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
    };
  },

  computed: {
    isDevelopmentMode() {
      return process.env.NODE_ENV === 'development';
    },

    notionRedirectUrl() {
      return `${axios.defaults.baseURL}/redirect/notion`;
    },
  },

  methods: {
    open() {
      this.dialog = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    close() {
      this.reject();
      this.dialog = false;
    },

    onSubmit() {
      this.resolve();
      this.dialog = false;
    },
  },
};
</script>
